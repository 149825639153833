<template>
  <div>
    <v-container fluid>
      <v-card class="shadow rounded-lg">

        <Overlay :overlay="overlay"/>

        <v-row align="center" class="pa-0 ma-0 py-1 mx-1">
          <v-col cols="12" lg="3">
            <v-text-field v-model.trim="keyword"
                          class="rounded-input"
                          clearable
                          dense
                          hide-details
                          outlined
                          placeholder="Recherche..." prepend-inner-icon="mdi-magnify"
                          single-line/>
          </v-col>
          <v-col class="text-end" cols="12" lg="9">

            <v-btn v-if="Object.keys(filter).length"
                   color="red"
                   text @click="[filter = {},getCampaigns()]">
              <v-icon left>mdi-filter-off</v-icon>
              Filtre
            </v-btn>

            <v-btn class="mr-2" color="primary" text @click="$refs.filterCampaignDialog.open()">
              <v-icon left>mdi-filter</v-icon>
              Filtre
            </v-btn>

            <v-btn class="mr-2"
                   color="primary"
                   v-if="roles('admin')"
                   text
                   @click="$func.export('campaign/export-all-work')">
              <v-icon left>mdi-export</v-icon>
              Exporter
            </v-btn>

            <v-btn class="mx-2"
                   v-if="roles('admin | regional')"
                   color="primary"
                   depressed to="/campaigns/create">
              <v-icon left>mdi-plus</v-icon>
              Campagne
            </v-btn>

            <v-btn color="primary" icon @click="getCampaigns">
              <v-icon>mdi-table-refresh</v-icon>
            </v-btn>

          </v-col>
        </v-row>

        <v-divider/>

        <v-card-text>

          <v-skeleton-loader v-if="isLoading" type="table"/>

          <div v-else>
            <div v-if="campaigns.length">
              <v-simple-table class="table-border rounded-lg">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>Statut</th>
                    <th>Image</th>
                    <th>Nom</th>
                    <th>Couleur</th>
                    <th>Qte min</th>
                    <th>Date de début</th>
                    <th>Date de fin</th>
                    <th>Animateurs</th>
                    <th>Ventes</th>
                    <th class="text-center">Jour restants</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,index) in campaigns" :key="index">
                    <td>
                      <v-switch v-model="item.is_active"
                                :style="{width: '10px'}"
                                class="pa-0 ma-0"
                                color="secondary"
                                hide-details
                                readonly
                                @click="changeStatus(item.id)"></v-switch>
                    </td>

                    <td>
                      <div class="py-2">
                        <v-avatar rounded size="42">
                          <v-img v-if="item.image" :src="$baseUrl + item.image"
                                 class="zoom-pointer rounded-lg"
                                 max-width="42"
                                 min-height="42"
                                 @click="$refs.lightbox.open(item.image)"/>

                          <v-img v-else
                                 :src="require('@/assets/no-image.png')"
                                 class="rounded-lg"
                                 max-width="42"
                                 min-height="42"></v-img>
                        </v-avatar>

                      </div>
                    </td>

                    <td class="text-no-wrap">
                      {{ item.name }}
                    </td>

                    <td>
                      <span :style="'color:'+item.color" class="text-uppercase d-flex align-center">
                        <v-icon :color="item.color" size="20">mdi-circle</v-icon>
                           {{ item.color }}
                      </span>
                    </td>

                    <td>
                      {{ item.min_product_qty }}
                    </td>

                    <td class="text-no-wrap">
                      <v-icon color="secondary" size="15">mdi-clock</v-icon>
                      {{ $func.dateFormat(item.start_date) }}
                    </td>

                    <td class="text-no-wrap">
                      <v-icon color="secondary" size="15">mdi-clock</v-icon>
                      {{ $func.dateFormat(item.end_date) }}
                    </td>

                    <td>
                      <v-chip :disabled="!item.animators.length"
                              class="font-weight-medium bg-pink-subtle"
                              small
                              text-color="pink"
                              @click="$refs.animatorsListDialog.open(item.animators)">
                        {{ item.animators.length }} Animateur(s)
                        <v-icon color="pink" right small>mdi-arrow-right</v-icon>
                      </v-chip>
                    </td>

                    <td>
                      <v-chip class="bg-blue-subtle font-weight-medium"
                              small
                              @click="$refs.allSalesDialog.open(item.id)"
                              text-color="blue">
                        {{ item.sales_count }}
                        <v-icon color="blue" right small>mdi-shopping</v-icon>
                      </v-chip>
                    </td>

                    <td class="text-center">
                      <v-chip :class="'bg-'+item.remaining_days.color+'-subtle'"
                              :text-color="item.remaining_days.color"
                              class="font-weight-medium"
                              small>
                        {{ item.remaining_days.value }}
                      </v-chip>
                    </td>

                    <td>

                      <div class="d-flex">

                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon
                                   v-bind="attrs"
                                   v-on="on"
                            >
                              <v-icon size="20">mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense>

                            <v-list-item v-if="roles('admin')"
                                         @click="$func.export('campaign/'+item.id+'/export-work')">
                              <v-list-item-avatar>
                                <v-icon>mdi-export</v-icon>
                              </v-list-item-avatar>
                              <v-list-item-title>Expoter les ventes</v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="$refs.animatorsListDialog.open(item.animators)">
                              <v-list-item-avatar>
                                <v-icon>mdi-clipboard-list-outline</v-icon>
                              </v-list-item-avatar>
                              <v-list-item-title>Liste des ventes</v-list-item-title>
                            </v-list-item>


                            <v-list-item v-if="roles('admin | regional')"
                                         @click="$refs.editDateDialog.open(item)">
                              <v-list-item-avatar>
                                <v-icon>mdi-update</v-icon>
                              </v-list-item-avatar>
                              <v-list-item-title>Prolonger date de fin</v-list-item-title>
                            </v-list-item>

                            <v-list-item v-if="roles('admin | regional')"
                                         :to="'campaigns/'+item.id+'/update'">
                              <v-list-item-avatar>
                                <v-icon>mdi-pencil-outline</v-icon>
                              </v-list-item-avatar>
                              <v-list-item-title>Modifier</v-list-item-title>
                            </v-list-item>

                            <v-list-item @click="deleteCampaign(item.id,index)"
                                         v-if="roles('admin | regional')">
                              <v-list-item-avatar>
                                <v-icon color="red">mdi-trash-can</v-icon>
                              </v-list-item-avatar>
                              <v-list-item-title>Supprimer</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>

                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-card-actions v-if="total > 10">

                <div>
                  <v-select v-model="per_page"
                            :items="[10,20,50,100,200,300,400,500]"
                            :style="{width: '120px'}"
                            dense
                            hide-details
                            label="Ligne par page"
                            outlined></v-select>
                </div>

                <v-spacer/>
                <v-pagination v-if="total>10"
                              v-model="pagination.current"
                              :length="pagination.total"
                              total-visible="6"
                              @input="onPageChange"
                ></v-pagination>
              </v-card-actions>

            </div>
            <NoResult v-else/>
          </div>

        </v-card-text>
      </v-card>


      <Lightbox ref="lightbox"/>
      <ConfirmDialog ref="confirmDialog"/>
      <FilterCampaignDialog ref="filterCampaignDialog"
                            @search="[pagination.current = 1,filter = $event,getCampaigns()]"/>
      <UpdateBrandsProductsDialog ref="updateBrandsProductsDialog"/>
      <UpdateGiftsDialog ref="updateGiftsDialog"/>
      <UpdateInfoDialog ref="updateInfoDialog"/>
      <DetailDialog ref="detailDialog"/>
      <AnimatorsListDialog ref="animatorsListDialog"/>
      <EditDateDialog ref="editDateDialog" @refresh="getCampaigns"/>
      <AllSalesDialog ref="allSalesDialog"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import FilterCampaignDialog from "@/views/campaigns/components/FilterCampaignDialog.vue";
import AnimatorsListDialog from "@/views/campaigns/components/AnimatorsListDialog.vue";

import UpdateBrandsProductsDialog from "@/views/campaigns/components/UpdateBrandsProductsDialog.vue";
import UpdateGiftsDialog from "@/views/campaigns/components/UpdateGiftsDialog.vue";
import UpdateInfoDialog from "@/views/campaigns/components/UpdateInfoDialog.vue";
import DetailDialog from "@/views/campaigns/components/DetailDialog.vue";
import EditDateDialog from "@/views/campaigns/components/EditDateDialog.vue";
import AllSalesDialog from "@/views/campaigns/components/AllSalesDialog.vue";

export default {
  components: {
    AnimatorsListDialog,
    UpdateBrandsProductsDialog,
    EditDateDialog,
    AllSalesDialog,
    DetailDialog,
    UpdateGiftsDialog,
    UpdateInfoDialog,
    FilterCampaignDialog
  },
  data() {
    return {
      campaigns: [],
      overlay: false,
      isLoading: false,

      keyword: null,
      total: 0,
      per_page: 10,
      pagination: {
        current: 1,
        total: 0
      },
      filter: {}
    }
  },

  methods: {
    getCampaigns() {
      this.isLoading = true
      HTTP.get('/campaigns?page=' + this.pagination.current, {
        params: {
          keyword: this.keyword,
          per_page: this.per_page,
          filter: this.filter
        }
      }).then((res) => {
        this.isLoading = false
        this.campaigns = res.data.data.data

        this.pagination.current = res.data.data.current_page
        this.pagination.total = res.data.data.last_page
        this.total = res.data.data.total

      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    async deleteCampaign(id, index) {
      if (await this.$refs.confirmDialog.open()) {
        this.overlay = true
        HTTP.delete('campaigns/' + id + '/delete').then(() => {
          this.campaigns.splice(index, 1)
          this.overlay = false
          this.$successMessage = 'Cet élément a été supprimé avec succès.'
        }).catch(err => {
          this.overlay = false
          console.log(err)
        })
      }
    },
    async changeStatus(campaignId) {
      if (await this.$refs.confirmDialog.open('', 'Êtes-vous sûr de vouloir changer le statut de cette campagne ?', {
        icon: 'mdi-backup-restore',
        agreeText: 'Changer',
        color: 'orange',
      })) {
        this.overlay = true
        HTTP.post('/campaigns/' + campaignId + '/change-status').then(() => {
          this.overlay = false
          this.getCampaigns()
          this.$successMessage = 'Cette campagne a été modifiée avec succès.'
        }).catch(err => {
          this.overlay = false
          console.log(err)
        })
      }
    },
    onPageChange() {
      this.getCampaigns();
    },
  },
  created() {
    this.getCampaigns()
  },
  watch: {
    keyword() {
      this.getCampaigns();
    },
    per_page() {
      this.pagination.current = 1
      this.getCampaigns();
    },
  },
}
</script>

<style scoped>

</style>