<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="800">

      <v-card>

        <v-card-title>
          Plus détails
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-10">
<!--          {{ campaign }}-->
        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>


export default {
  data() {
    return {
      isLoading: false,
      dialog: false,
      campaign: {},
    }
  },
  methods: {
    open(campaign) {
      this.dialog = true
      this.campaign = campaign
    },

  }
}
</script>

<style scoped>

</style>